<template>
    <tr>
        <td>{{ index + 1 }}</td>
        <td>{{ employe.username }}</td>
        <td>
            <div class="link" @click.prevent="logInAsStudent(employe.login)">{{ employe.login }}</div>
        </td>
        <td>
            <EditCell
                :type="'date'"
                :code="'birthday'"
                :value="employe.birthday"
                @update:edit="sendCellValue"
            />
        </td>
        <td>
            <EditCell
                :type="'text'"
                :code="'snils'"
                :value="employe.snils"
                @update:edit="sendCellValue"
            />
        </td>
        <td>
            <EditCellFile
                :value="employe.certificate"
                @update:edit="sendCellValue"
            />
        </td>
        <td>
            <EditCell
                :type="'text'"
                :code="'work'"
                :value="employe.work"
                @update:edit="sendCellValue"
            />
        </td>
        <td>
            <EditCell
                :type="'text'"
                :code="'position'"
                :value="employe.position"
                @update:edit="sendCellValue"
            />
        </td>
        <td>
            <button class="btn btn-delete" @click="isShowModal = true">
                <img src="/img/icons/XCircle.svg" />
                Удалить
            </button>
        </td>
        <BaseModal v-if="isShowModal" @close="closeModal">
            <template v-if="!errors">
                <div class="modal__title subtitle">Вы действительно хотите удалить сотрудника?</div>
                <div class="modal__btn">
                    <button class="btn btn-red" @click="deleteEmployees">Да, удалить</button>
                </div>
            </template>
            <div class="modal__title subtitle" v-else>{{ errors }}</div>
        </BaseModal>
    </tr>
</template>

<script>
import EditCell from "@/pages/personal/components/edit-cell"
import EditCellFile from "@/pages/personal/components/edit-cell-file";
import BaseModal from "@/components/modals/base-modal";

export default {
    props: {
        employe: {
            type: Object,
            default: () => {}
        },
        index: {
            type: Number,
            default: 0
        }
    },

    components: {
        EditCell,
        EditCellFile,
        BaseModal
    },

    data() {
        return {
            isShowModal: false,
            errors: null,
        }
    },

    methods: {
        deleteEmployees() {
            this.$store.dispatch("CUSTOMER_DELETE_EMPLOYEES", this.employe.id)
                .then(() => this.isShowModal = false)
                .catch((error) => this.errors = error.response.data.message)
        },
        sendCellValue(formData) {
            formData.append("id", this.employe.id)

            this.$store.dispatch("CUSTOMER_UPDATE_DATA_STUDENTS", formData)
                .then(response => this.$store.commit("CUSTOMER_SET_EMPLOYEES", response.data))
        },
        logInAsStudent(login) {
            this.$store.dispatch("AUTH_LOGIN_REQUEST", { password: login, role: "student" })
            .then(() =>
                this.$store.dispatch("PERSONAL_FETCH_USER_DATA").then(() => this.$router.push('/'))
            )
        },
        closeModal() {
            this.isShowModal = false
            this.errors = null
        }
    }
}
</script>
