<template>
    <div class="modal-mask">
        <div class="modal-wrapper" ref="modalWrapper">
            <div class="modal modal--message" :class="classes">
                <slot v-if="classes === 'modal--test'" />

                <template v-else>
                    <button class="modal__close" @click="$emit('close')">
                        <svg class="icon icon-close-1">
                            <use xlink:href="#close-1"></use>
                        </svg>
                    </button>

                    <div class="modal__body">
                        <slot />
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
// import { ref, onMounted, onBeforeUnmount, onUpdated } from "vue";

export default {
    props: {
        classes: String
    },

    // setup() {
    //     const modalWrapper = ref(null);
    //     const body = document.querySelector(".js-body");

    //     // const closeClickNotCurrentArea = event => {
    //     //     if (event.target === modalWrapper.value) emit('close')
    //     // }

    //     onMounted(() => {
    //         // document.addEventListener('click', closeClickNotCurrentArea, false)
    //         body.classList.add("body--fix");
    //     });
    //     onUpdated(() => {
    //         if (!body.classList.value.includes("body--fix"))
    //             body.classList.add("body--fix");
    //     });
    //     onBeforeUnmount(() => {
    //         // document.removeEventListener('click', closeClickNotCurrentArea, false)
    //         body.classList.remove("body--fix");
    //     });

    //     return { modalWrapper };
    // }
};
</script>
