<template>
    <div class="editable-cell">
        <label
            class="btn-editable"
            :class="value ? 'btn-editable--blue' : 'btn-editable--red'"
            v-if="isEdited && !isLoading"
        >
            <svg class="icon icon-pencil-1">
                <use xlink:href="#pencil-1"></use>
            </svg>
            Загрузить
            <input type="file" hidden @change="saveFile">
        </label>
        <template v-if="isLoading">Загрузка...</template>
        <template v-if="!isEdited">Загружен</template>
    </div>
</template>

<script>
export default {
    props: {
        value: String
    },

    data() {
        return {
            isEdited: true,
            isLoading: false
        }
    },

    mounted() {
        if (this.value) this.isEdited = false
    },

    methods: {
        saveFile(event) {
            if (event.target.files[0]) {
                const formData = new FormData()
                formData.append('certificate', event.target.files[0])
                this.isLoading = true

                this.$store.dispatch('PERSONAL_SET_CERTIFICATE', formData)
                    .then(response => {
                        const certificateFormData = new FormData()
                        certificateFormData.append('certificate', response.data)
                        this.$emit('update:edit', certificateFormData)
                        this.isEdited = false
                    })
                    .catch(() => this.isEdited = true)
                    .finally(() => this.isLoading = false)
            }
        },
    },
}
</script>
