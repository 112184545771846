<template>
    <div class="table table--employees" v-if="employees.length">
        <table>
            <thead>
                <tr>
                    <td>№</td>
                    <td>ФИО</td>
                    <td>Логин</td>
                    <td>Дата рождения</td>
                    <td>СНИЛС</td>
                    <td>Документ об образовании</td>
                    <td>Место работы</td>
                    <td>Должность</td>
                    <td></td>
                </tr>
            </thead>
            <tbody>
                <employeesRow v-for="(employe, index) in employees" :key="employe.id" :index="index" :employe="employe" />
            </tbody>
        </table>
    </div>
    <div class="title-second" v-else>
        Сотрудники отсутствуют.
    </div>
</template>

<script>
import store from "@/store/index.js";
import employeesRow from "@/pages/personal/customer/employees/employees-table-row";

export default {
    beforeRouteEnter(to, from, next) {
        store.dispatch("CUSTOMER_GET_EMPLOYEES").then(() => {
            next();
        });
    },

    components: {
        employeesRow
    },

    computed: {
        employees() {
            return this.$store.state.customer.employees;
        }
    },
};
</script>
