<template>
    <div class="editable-cell">
        <label
            class="btn-editable"
            :class="value ? 'btn-editable--blue' : 'btn-editable--red'"
            @click="editCell"
        >

            <template v-if="!isEditTextCell && !value">
                <svg class="icon icon-pencil-1">
                    <use xlink:href="#pencil-1"></use>
                </svg>
                Заполнить
            </template>

            <input
                ref="input"
                class="input input--hidden"
                :type="type"
                :value="newValue"
                v-model="newValue"
                v-else
                @input="saveValue"
                @blur="closeEditing"
                @paste="saveValue"
            >
        </label>
    </div>

    <!-- <div class="editable-cell editable-cell--error">
        <label class="btn-editable">
            <svg class="icon icon-Warning ">
                <use xlink:href="#Warning"></use>
            </svg>
            <input type="text" value="166-752-099 00" class="input input--hidden">
        </label>
        <div class="tooltip">
            <div class="tooltip__message">Неверно введен СНИЛС</div>
        </div>
    </div> -->

</template>

<script>
import InputMask from "inputmask";

export default {
    props: {
        type: String,
        value: String,
        code: String
    },

    data() {
        return {
            isEditTextCell: false,
            newValue: "",
            inputMask: "",
        }
    },

    mounted() {
        if (this.value) {
            if (this.type === 'date') this.newValue = this.reverseDate(this.value)
            else this.newValue = this.value
        }
    },

    methods: {
        reverseDate(date) {
            return date && date.split('.').reverse().join('-')
        },
        closeEditing() {
            if (!this.newValue) this.isEditTextCell = false
        },
        editCell() {
            this.isEditTextCell = true
            this.addMaskSnils()
        },
        saveValue() {
            const formData = new FormData()
            formData.append(this.code, this.newValue)

            this.$emit('update:edit', formData)
            this.closeEditing()
        },
        addInputMask(selector) {
            InputMask(this.inputMask).mask(selector)
        },
        deleteInputMask(selector) {
            selector.inputmask.remove()
        },
        addMaskSnils() {
            if (this.code === "snils") {
                this.inputMask = "999-999-999 99";
                this.addInputMask(this.$refs.input);
            }

        }
    },
}
</script>
